
/*
*
*
*
*
*/
//const _version_num = '/v1'
const _internal_version = '/internalv1'
const axios = require('axios');
import { CONSTANTS, show_debug } from './forge';

/*
*
*
*
*
*/
export const redeem_direct_v2 = async function(kwargs){

    //
    //
    let _type = ''
    let _url = ''

    //
    //
    let has_data = false
    let has_headers = false

    //
    //
    if('url' in kwargs){

        //
        //
        _url = kwargs['url']

        //
        //
        if('param' in kwargs) {

            for (let i = 0; i < kwargs['param'].length; i++) {
                let _k = kwargs['param'][i]['key']
                let _v = kwargs['param'][i]['value']
                _url =  _url + '&' + _k + '=' + encodeURIComponent(_v)
            }
    
        }

    }
    else {
        return {'result': 'missing url'}
    }

    //
    //
    if('type' in kwargs){
        _type = kwargs['type']
    }
    else {
        return {'result': 'missing query type'}
    }

    //
    //
    if('data' in kwargs){ has_data = true }
    if('headers' in kwargs){ has_headers = true }

    //
    //
    if(_type=='GET'){

        //
        //
        if('headers' in kwargs){

            //
            //
            if(has_headers == true){

                //
                //
                return await axios.get(
                    _url, 
                    {
                        headers: kwargs['headers']
                    },
                )

            }
            else {

                //
                //
                return await axios.get(_url)

            }
            
        }
        else {

            //
            //
            return await axios.get(_url)
        }

    }
    else if(_type=='POST'){

        //
        //
        if(has_data==true && has_headers==true){

            //
            //
            return await axios.post(
                _url,
                kwargs['data'],
                {
                    headers: kwargs['headers']
                },
            )

        }
        else if(has_data==false && has_headers==false){

            //
            //
            return await axios.post(_url)

        }
        else if(has_data==true && has_headers==false){

            //
            //
            return await axios.post(
                _url,
                kwargs['data'],
            )

        }
        else if(has_data==false && has_headers==true){

            //
            //
            return await axios.post(
                _url,
                {
                    headers: kwargs['headers']
                },
            )

        }
        
    }
    else if(_type=='PUT'){

        //
        //
        if(has_data==true && has_headers==true){

            //
            //
            return await axios.put(
                _url,
                kwargs['data'],
                {
                    headers: kwargs['headers']
                },
            )

        }
        else if(has_data==false && has_headers==false){

            //
            //
            return await axios.post(_url)

        }
        else if(has_data==true && has_headers==false){

            //
            //
            return await axios.post(
                _url,
                kwargs['data'],
            )

        }
        else if(has_data==false && has_headers==true){

            //
            //
            return await axios.post(
                _url,
                {
                    headers: kwargs['headers']
                },
            )

        }
        
    }
    else if(_type=='PATCH'){

        //
        //
        if(has_data==true && has_headers==true){

            //
            //
            return await axios.update(
                _url,
                kwargs['data'],
                {
                    headers: kwargs['headers']
                },
            )

        }
        else if(has_data==false && has_headers==false){

            //
            //
            return await axios.update(_url)

        }
        else if(has_data==true && has_headers==false){

            //
            //
            return await axios.update(
                _url,
                kwargs['data'],
            )

        }
        else if(has_data==false && has_headers==true){

            //
            //
            return await axios.update(
                _url,
                {
                    headers: kwargs['headers']
                },
            )

        }
        
    }
    else if(_type=='DELETE'){

        //
        //
        if(has_data==true && has_headers==true){

            //
            //
            return await axios.delete(
                _url,
                kwargs['data'],
                {
                    headers: kwargs['headers']
                },
            )

        }
        else if(has_data==false && has_headers==false){

            //
            //
            return await axios.delete(_url)

        }
        else if(has_data==true && has_headers==false){

            //
            //
            return await axios.delete(
                _url,
                kwargs['data'],
            )

        }
        else if(has_data==false && has_headers==true){

            //
            //
            return await axios.delete(
                _url,
                {
                    headers: kwargs['headers']
                },
            )

        }
        
    }

}

/*
*
*
*
*
*/
export const redeem_direct = async function(kwargs){

    //
    //
    let _type = ''
    let _url = kwargs['url']

    //
    //
    if('type' in kwargs){
        _type = kwargs['type']
    }
    else {
        _type = 'GET'
    }

    //
    //
    if(_type=='GET'){

        //
        //
        if('headers' in kwargs){

            //
            //
            return await axios.get(
                _url, 
                kwargs['headers'],
                {
                    headers: kwargs['headers']
                },
            )

        }
        else {

            //
            //
            return await axios.get(_url)
        }
        
    }
    else if(_type=='POST'){

        //
        //
        if('headers' in kwargs){

            //
            //
            if('param' in kwargs) {

                for (let i = 0; i < kwargs['param'].length; i++) {
                    let _k = kwargs['param'][i]['key']
                    let _v = kwargs['param'][i]['value']
                    _url =  _url + '&' + _k + '=' + encodeURIComponent(_v)
                }
        
            }

            //
            //
            return await axios.post(
                _url,
                kwargs['data'],
                {
                    headers: kwargs['headers']
                },
            )

            
            
        }
        else {

            //
            //
            if('param' in kwargs) {

                for (let i = 0; i < kwargs['param'].length; i++) {
                    let _k = kwargs['param'][i]['key']
                    let _v = kwargs['param'][i]['value']
                    _url =  _url + '&' + _k + '=' + encodeURIComponent(_v)
                }
        
            }

            //
            //
            return await axios.post(_url,kwargs['data'])
        }
        
    }
    
}

/*
*
*
*
*
*/
export const redeem_api = async function(kwargs){

    /*
    * --------- HOW TO USE ---------------
    * 
    * kwargs = {
    *   'domain': 'http://localhost:8081/',
    *   'key': this.key,
    *   'type': 'POST',
    *   'url': '/api/query/',
    *   'data': {
    *       key: value,
    *   },
    *   'debug': true,
    * }
    * 
    * kwargs = {
    *   'domain': 'http://localhost:8081/',
    *   'key': this.key,
    *   'type': 'GET',
    *   'url': '/api/query/',
    *   'param': [
    *       {
    *           'key': 'value',
    *       },
    *   ],
    *   'debug': true,
    * }
    * 
    * --------------------------------------
    * 
    */

    /*
    *
    *
    *
    *
    *
    */
    let _domain = CONSTANTS['DOMAIN_API']
    let _type = kwargs['type']              // GET / POST
    let _key = ''

    /*
    *
    *
    *
    *
    *
    */
    if('key' in kwargs){
        _key = '&token=' + String(kwargs['key']) // api key
    }
    else {
        _key = ''
    }

    /*
    *
    *
    *
    *
    *
    */
    let _url = ''
    if('is_api' in kwargs){
        if(kwargs['is_api']==true) {
            _url = _domain + _internal_version + kwargs['url'] + '?format=json' + _key
        }
        else {
            _url = _domain + kwargs['url'] + '?format=json' + _key
        }
    }
    else {
        _url = _domain + _internal_version + kwargs['url'] + '?format=json' + _key    // '/v1/query/
    }
    show_debug(_url)

    /*
    *
    *
    *
    *
    *
    */
    /*
    * used for get url param
    *
    kwargs['param'] = [
            {
                'key':'your_key',
                'value': 'your_value',
            },
        ]
    */
    if('param' in kwargs) {

        for (let i = 0; i < kwargs['param'].length; i++) {
            let _k = kwargs['param'][i]['key']
            let _v = kwargs['param'][i]['value']
            _url =  _url + '&' + _k + '=' + encodeURIComponent(_v)
        }

    }

    /*
    *
    *
    *
    *
    *
    */
    // let _config = {
    //     headers: {
    //         //"Access-Control-Allow-Origin": "*",
    //     }
    // }

    /*
    *
    * data
    *
    *
    *
    */
    if(_type == 'GET') {
        
        //
        //
        return await axios.get(_url)
    }
    else if(_type == 'POST') {

        //
        //
        /*
        POST will require data
            {
                key1: value1,
                key2: value2,
            }
        */
        return await axios.post(_url,kwargs['data'])
    }

}

// ==================================================================
// re-usable specific functions here
// ==================================================================

//
//
export const redeem_upload_img = async function(url,data){

    return await axios.post(url,data)

}

//
//
export const redeem_ipaddress = async function(){

    //
    //
    let u = 'https://www.cloudflare.com/cdn-cgi/trace'
    return await axios.post(u)
}

//
//
export const redeem_geolocation = async function(ipaddress){

    //
    //
    let u = 'http://ip-api.com/json/' + ipaddress + '?fields=lat,lon'
    return await axios.post(u)

}

//
//
export const redeem_keyviacode = async function(code){

    //
    //
    show_debug(code)
    let uri = CONSTANTS['DOMAIN_API'] + '/dj-rest-auth/google/?format=json'
    show_debug(uri)
    
    //
    //
    return await axios.post(uri,{
        code: code,
    })
}

//
//
export const redeem_keyvialogin = async function(domain,user,pass){

    //
    //
    let uri = CONSTANTS['DOMAIN_API'] + '/dj-rest-auth/login/?format=json'
    show_debug(uri)

    //
    //
    return await axios.post(uri,{
        username:user,
        password:pass,
    })
}

//
//
export const redeem_keyviaregistration = async function(e,u,p1,p2){

    //
    //
    let uri = CONSTANTS['DOMAIN_API'] + '/dj-rest-auth/registration/?format=json'
    show_debug(uri)

    //
    //
    return await axios.post(uri,{
        username:u,
        password1:p1,
        password2:p2,
        email:e,
    })
}

//
//
export const redeem_resend_otp = async function(token){
    let uri = CONSTANTS['DOMAIN_API'] + _internal_version + '/session/resend-otp/'
    return await axios.post(uri,{
        token: token,
    })
}

//
//
export const redeem_verify_otp = async function(token,number) {
    let uri = CONSTANTS['DOMAIN_API'] + _internal_version + '/session/verify-otp/'
    return await axios.post(uri,{
        token: token,
        number: number,
    })
}








