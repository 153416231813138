
/*
*
*
*
*
*/
//const _version_num = '/v1'
// const _internal_version = '/internalv1'
// const axios = require('axios');
// import * as redeemer from './redeemer'
import { CONSTANTS, show_debug, REDIRECT_TO, format_cloudflare } from './forge'
import { redeem_api, redeem_ipaddress } from './redeemer'
// import { cookie_class, localstorage_class } from './keymaster_session'
// import { keymaster_query_class } from './keymaster_queries'

// ==================================================================
// cookie management
// ==================================================================

//
//
export const set_cookie = function(cname, cvalue, expiry_days) {
    
    //
    //
    const d = new Date();
    d.setTime(d.getTime() + (expiry_days * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//
//
export const get_cookie_value = function(cname){

    //
    //
    let name = cname + "=";
    let ca = document.cookie.split(';');

    //
    //
    for(let i = 0; i < ca.length; i++) {

        //
        //
        let c = ca[i];

        //
        //
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }

    //
    //
    return ""
}

//
//
export const delete_cookie = function(cname){

    //
    //
    let _key = cname

    //
    //
    set_cookie(_key,false,-1)

    //
    //
    //document.cookie = _key +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

}

//
//
export const if_cookie_exists = function(cname){

    // returns true or false

    //
    //
    let the_cookie = get_cookie_value(
        cname
    );

    //
    //
    if (the_cookie != "") {
        return true
    } else {
        return false
    }
}

// ==================================================================
// localstorage management
// ==================================================================

//
// save value to localstorage - v is json data
export const save_ls = function(v){

    //
    //
    let _key = CONSTANTS['COOKIE_NAMES']['localstorage']

    //
    //
    try {
        window.localStorage.setItem(_key, JSON.stringify(v));
    } catch (error) {
        window.localStorage.setItem(_key, {});
    }

    //
    //
    JSON.parse(window.localStorage.getItem(_key))

}

//
//
export const get_ls = function(){

    //
    //
    let _key = CONSTANTS['COOKIE_NAMES']['localstorage']

    //
    //
    return JSON.parse(window.localStorage.getItem(_key))
}

//
//
export const if_ls_exists = function(){

    //
    //
    if( get_ls()==null || get_ls()==undefined || get_ls()=='' ) {
        return false
    }
    else {
        return true
    }

}

//
//
export const delete_ls = function(){

    //
    //
    //let _key = CONSTANTS['LOCALSTORAGE_NAME']

    //
    //
    //localStorage.removeItem(_key)
    localStorage.clear()

}

//
//
export const set_default_ls = function(){

    //
    //
    let ls_default_values = {
        'i': '0', // profile id, string
        'u': '', // company slug
        'c': {}, // company settings
        'a': 0, // access level, string
        'p': {}, // company role privilege
        's': 0, // sidebar, 0 - visible, 1 - hidden
        'pv': 'kanban',
        'av': 'kanban',
        'cv': 'kanban',
        'lv': 'table',
    }
    save_ls(ls_default_values)

}

//
//
export const manage_state = function(key,v){

    //
    //
    try {
        
        //
        //
        let ls = get_ls()
        ls[key] = v
        save_ls(ls)

    } catch (error) {
        
        show_debug(error)

    }

}

//
//
export const view_state = function(key){

    //
    //
    try {
        
        //
        //
        let ls = get_ls()
        return ls[key]

    } catch (error) {
        
        show_debug(error)

    }

}

//
//
// export const view_slug = function(key){

//     //
//     //
//     let ls = get_ls()
//     return ls[key]

// }

// ==================================================================
// state management
// ==================================================================

//
//
export const login_session = function(session_id,api_key){

    //
    //
    let key1 = CONSTANTS['COOKIE_NAMES']['session']
    set_cookie(key1,session_id,30)

    //
    //
    let key2 = CONSTANTS['COOKIE_NAMES']['key']
    set_cookie(key2,api_key,30)

    //
    //
    set_default_ls()

}

//
//
export const logout_session = function(){

    //
    //
    let key1 = CONSTANTS['COOKIE_NAMES']['session']
    set_cookie(key1,false,-1)

    //
    //
    let key2 = CONSTANTS['COOKIE_NAMES']['key']
    set_cookie(key2,false,-1)

    //
    //
    // let key3 = CONSTANTS['COOKIE_NAMES']['darkmode']
    // set_cookie(key3,false,-1)

    //
    //
    delete_ls()

    //
    //
    REDIRECT_TO('/login/')

}

//
//
export const loggedin_area = function(){

    //
    //
    show_debug('loggedin_area')

    //
    //
    let session = CONSTANTS['COOKIE_NAMES']['session']
    let key = CONSTANTS['COOKIE_NAMES']['key']

    //
    //
    if(if_cookie_exists(key) == false){

        //
        //
        if(if_cookie_exists(session) == false){
            
            return false

        }

    }
    else {

        //
        // if localstorage does not exists
        if(if_ls_exists() == false){

            //
            // set default localstorage
            set_default_ls()

        }

    }

    //
    // match loggedin details
    return if_session_match(session,key)

}

//
//
export const get_darkmode = function(){

    //
    //
    let key = CONSTANTS['COOKIE_NAMES']['darkmode']

    //
    //
    return get_cookie_value(key)

}

//
//
export const toggle_darkmode = function(){

    //
    //
    let key = CONSTANTS['COOKIE_NAMES']['darkmode']

    //
    //
    if(if_cookie_exists(key)== false){

        //
        //
        set_darkmode()

    }
    else {

        //
        //
        let v = get_cookie_value(key)

        //
        //
        if(v==0){

            //
            //
            set_darkmode()

        }
        else {

            //
            //
            set_lightmode()

        }

    }

}

//
//
export const set_darkmode = function(){

    //
    //
    let key = CONSTANTS['COOKIE_NAMES']['darkmode']

    //
    //
    set_cookie(key,1,3000) // set darkmode for 30 days

    //
    //
    document.body.classList.add("dark-only")

}

//
//
export const set_lightmode = function(){

    //
    //
    let key = CONSTANTS['COOKIE_NAMES']['darkmode']

    //
    //
    set_cookie(key,0,3000)

    //
    //
    document.body.classList.remove("dark-only")

}

//
//
export const get_key = function(){

    //
    //
    let key = CONSTANTS['COOKIE_NAMES']['key']

    //
    //
    if(if_cookie_exists(key)== false){
        return null
    }
    else {
        return get_cookie_value(key)
    }

}

//
//
export const toggle_sidebar = function(){
    
    // by default, sidebar should be visible, 0

    //
    //
    let v = get_ls()
    let sidebar = v['s'] // 0 or 1 - 0 hidden

    //
    //
    if(sidebar==0){

        //
        //
        v['s'] = 1
        save_ls(v)

        //
        //
        document.getElementById("id_sidebar").classList.add("close_icon")

    }
    else if(sidebar==1){

        //
        //
        v['s'] = 0
        save_ls(v)

        //
        //
        document.getElementById("id_sidebar").classList.remove("close_icon")

    }


}


// ==================================================================
// table, kanbban, list view checker
// ==================================================================

//
//
export const get_view = function(key){
    
    //
    //
    let ls = get_ls()

    //
    //
    if(key == 'assignments' || key == 'assignment'){
        return ls['av']
    }
    else if(key == 'clients' || key == 'client'){
        return ls['cv']
    }
    else if(key == 'personnels' || key == 'personnel'){
        return ls['pv']
    }
    else if(key == 'leaves' || key == 'leave'){
        return ls['lv']
    }
}

//
//
export const set_view = function(key,new_value){

    //
    //
    let ls = get_ls()

    //
    //
    if(key == 'assignments' || key == 'assignment'){
        ls['av'] = new_value
    }
    else if(key == 'clients' || key == 'client'){
        ls['cv'] = new_value
    }
    else if(key == 'personnels' || key == 'personnel'){
        ls['pv'] = new_value
    }
    else if(key == 'leaves' || key == 'leave'){
        ls['lv'] = new_value
    }

    //
    //
    save_ls(ls)

}

//
// uses redeemer
export const validate_views = function(){

    //
    //
    let ls = get_ls()
    let slug = ls['u'] // company slug

    //
    //
    if( ls['av'] == null || ls['cv'] == null || ls['pv'] == null || ls['lv'] == null){
        
        //
        //
        redeem_api({
            'key': get_key(),
            'type': 'GET',
            'url': '/company/get-views/' + slug + '/',
        })
        .then(response=>{

            //
            //
            show_debug(response)

            //
            //
            if(response.data.result != 'unknown error occured'){

                //
                //
                set_view('assignment',response.data['av'])
                set_view('client',response.data['cv'])
                set_view('personnel',response.data['pv'])
                set_view('leaves',response.data['lv'])
            }
        })
        .catch(error=>{

            //
            //
            show_debug(error)

            //
            //
            set_view('assignment','kanban')
            set_view('client','kanban')
            set_view('personnel','kanban')
            set_view('leaves','table')

        })

        return true

    }
    else {

        return true

    }
}

// ==================================================================
// verification, state related db queries
// ==================================================================

export const if_session_match = function(session,key){

    //
    //
    show_debug('if_session_match')

    //
    //
    redeem_ipaddress()
    .then(response=>{

        //
        //
        show_debug(response)

        //
        //
        let cf = format_cloudflare(response)

        //
        //
        redeem_api({
            'type': 'POST',
            'url': '/session/verify/',
            'data': {
                session         : get_cookie_value(session),
                ipaddress       : cf['ipaddress'],
                useragent       : cf['useragent'],
                country_code    : cf['country_code'],
                key             : get_cookie_value(key),
            }
        })
        .then(response=>{

            //
            //
            show_debug(response.data)

            //
            //
            return response.data // either true or false

        })
        .catch(error=>{

            //
            //
            show_debug(error)

            //
            //
            return false

        })

    })
    .catch(error=>{

        //
        //
        show_debug(error)

        //
        //
        return false

    })

}

// ==================================================================
// personnel role management, transfer this to new keymaster too
// ==================================================================

//
//
export const has_feature = function(){

    //
    //
    let v = view_state('role_privilege')
    let r = {

    }
    
    
    return [v,r]

}



