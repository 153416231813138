
//import * as pendulum from './pendulum'

// ==================================================================
// workflow builder pan, move, zoom, add objects, connect objects
// ==================================================================

//
//
//
// this is only a helper class, not the engine, the engine is on the vue file
export class workflow_class {

    //
    //
    constructor(){

        //
        //
        // let trigger_size = 70
        //let connector_point_size = 20
        // let connector_link_width = 20

    }

    // ==================================================================
    // ==================================================================
    // create objects
    // ==================================================================
    // ==================================================================

    //
    //
    draw_gridlines(cvs,ctx,offset,zoom_scale,grid_size,grid_color,grid_center_line_color){

        //
        //
        //let grid_size = 20
        let w = cvs.clientWidth * 10
        let h = cvs.clientHeight * 10

        //
        //
        if(grid_size==0 || grid_size=='' || grid_size==undefined || grid_size==null){
            grid_size=20
        }

        //
        // draw lines to the right
        for (
            let x = offset.x; 
            x < w; 
            x += grid_size * zoom_scale
        ) {
            this.draw_line(ctx,x,0,x,h,grid_color,1)
        }

        //
        // draw lines to the left
        for (
            let x = offset.x; 
            x > 0; 
            x -= grid_size * zoom_scale
        ) {
            this.draw_line(ctx,x,0,x,h,grid_color,1)
        }
        
        //
        // draw lines to the top
        for (
            let y = offset.y; 
            y < h; 
            y += grid_size * zoom_scale
        ) {
            this.draw_line(ctx,0,y,w,y,grid_color,1)
        }
        
        //
        // draw lines to the bottom
        for (
            let y = offset.y; 
            y > 0; 
            y -= grid_size * zoom_scale
        ) {
            this.draw_line(ctx,0,y,w,y,grid_color,1)
        }

        //
        //
        this.draw_line(ctx,offset.x,0,offset.x,h,grid_center_line_color,1)
        this.draw_line(ctx,0,offset.y,w,offset.y,grid_center_line_color,1)

    }

    //
    // circle is drawn from the center, unline rectangle
    // move this to crucible later
    //
    draw_origin(ctx,offset,xpos,ypos,size,_color,_border_color,zoom_scale){

        //
        //
        // let x = this.compute_xpos(xpos,offset)
        // let y = this.compute_ypos(ypos,offset)
        let x = offset.x + xpos
        let y = offset.y + ypos
        let font_size = 30

        //
        // outer-circle
        this.draw_circle(
            ctx,
            x,
            y,
            size + 10,
            _border_color,
            zoom_scale
        )

        //
        // inner-circle
        this.draw_circle(
            ctx,
            x,
            y,
            size,
            _color,
            zoom_scale
        )

        //
        //
        this.draw_text(
            ctx,
            'Select a module',
            font_size,
            //x - ( (size * 1.30) * this.zoom_scale),
            x,
            y - (size + font_size),
            '#000000',
            200,
            zoom_scale
        )

        //
        //
        this.draw_text(
            ctx,
            '+',
            100,
            //x - 30 * this.zoom_scale,
            x,
            y + 5,
            '#ffffff',
            150,
            zoom_scale
        )

    }

    //
    //
    draw_trigger(ctx,offset,xpos,ypos,size,_text,_color,text_color,icon_color,_border_color,zoom_scale){

        //
        //
        // let x = this.compute_xpos(xpos,offset,zoom_scale)
        // let y = this.compute_ypos(ypos,offset,zoom_scale)
        let x = offset.x + xpos
        let y = offset.y + ypos

        let font_size = 24

        //
        //
        let display_text = ''
        if(_text=='' || _text==undefined){
            display_text = 'empty'
        }
        else {
            display_text = _text.split('. ')[1]
        }

        //
        // outer-circle (border)
        this.draw_circle(
            ctx,
            x,
            y,
            size + 10,
            _border_color,
            zoom_scale
        )

        //
        // inner-circle
        this.draw_circle(
            ctx,
            x,
            y,
            size,
            _color,
            zoom_scale
        )

        //
        // draw text above
        this.draw_text(
            ctx,
            _text,
            font_size,
            //x - ( (size * 1.30) * this.zoom_scale),
            x, // center
            y - (size + font_size), // above circle
            text_color,
            500 * zoom_scale,
            zoom_scale
        )

        //
        // letters - initials (icon color)
        this.draw_text(
            ctx,
            display_text[0],
            80, // fontsize
            //x - 30 * this.zoom_scale,
            x,
            y + 5 * zoom_scale,
            icon_color,
            150 * zoom_scale,
            zoom_scale
        )

    }

    //
    //
    draw_empty_module(ctx,offset,xpos,ypos,border_color,zoom_scale){
        
        //
        //
        // let centerx = this.compute_xpos(xpos,offset,zoom_scale)
        // let centery = this.compute_ypos(ypos,offset,zoom_scale)
        let centerx = offset.x + xpos
        let centery = offset.y + ypos

        //
        //
        let w = 300
        let h = 100

        //
        //
        let movex = (w / 2)
        let movey = (h / 2)
        
        
        //
        //
        this.draw_rectangle(
            ctx,
            centerx - movex,
            centery - movey,
            w,
            h,
            '#000000',
            zoom_scale
        )

        //
        // draw module name
        this.draw_text(
            ctx,
            '+', // name
            100, // font size
            centerx ,
            centery,
            '#ffffff',
            w, // width
            zoom_scale
        )


    }

    //
    // x & y should be the center of the rectangle like on the circle
    draw_module(ctx,obj_id,offset,xpos,ypos,module_name,title,text_color,color,secondary_text_color,secondary_color,border_color,zoom_scale){

        //
        //
        let centerx = offset.x + xpos
        let centery = offset.y + ypos

        //
        //
        let w = 300
        let h = 100

        //
        //
        let movex = (w / 2)
        let movey = (h / 2)

        //
        // draw border
        this.draw_rectangle_border(
            ctx,
            centerx - movex,
            centery - movey,
            w,
            h,
            border_color,
            zoom_scale
        )

        //
        // draw header
        this.draw_rectangle(
            ctx,
            centerx - movex,
            centery - movey,
            w,
            h/2,
            color,
            zoom_scale
        )

        //
        // draw module name
        this.draw_text(
            ctx,
            obj_id + '. ' + module_name,
            20, // font size
            centerx,
            centery - 25,
            text_color,
            w,
            zoom_scale
        )

        //
        // draw body
        this.draw_rectangle(
            ctx,
            centerx - movex,
            centery,
            w,
            h/2,
            secondary_color,
            zoom_scale
        )

        //
        // draw title
        this.draw_text(
            ctx,
            title,
            20, // font size
            centerx,
            centery + 25,
            secondary_text_color,
            w,
            zoom_scale
        )

    }

    //
    //
    draw_connector_point(ctx,offset,xpos,ypos,size,zoom_scale,show_plus){

        //
        //
        // let x = this.compute_xpos(xpos,offset,zoom_scale)
        // let y = this.compute_ypos(ypos,offset,zoom_scale)
        let x = offset.x + xpos
        let y = offset.y + ypos

        //
        //
        this.draw_circle(
            ctx,
            x,
            y,
            size,
            '#000000',
            zoom_scale
        )

        //
        //
        if(show_plus==true){

            //
            //
            this.draw_text(
                ctx,
                '+',
                50, // font size
                //x - 30 * this.zoom_scale,
                x,
                y + 5,
                '#ffffff',
                50,
                zoom_scale
            )

        }

    }

    // not in use?
    //
    draw_connection(ctx,offset,obj1,obj2,hexcolor,zoom_scale){
        this.throwable_var = zoom_scale
        //
        // //
        // this.draw_line(
        //     ctx,
        //     offset.x + obj1.xpos,
        //     offset.y + obj1.ypos,
        //     offset.x + obj2.xpos,
        //     offset.y + obj2.ypos,
        //     // this.compute_xpos(obj1.xpos,offset,zoom_scale),
        //     // this.compute_ypos(obj1.ypos,offset,zoom_scale),
        //     // this.compute_xpos(obj2.xpos,offset,zoom_scale),
        //     // this.compute_ypos(obj2.ypos,offset,zoom_scale),
        //     hexcolor,
        //     20
        // )

    }

    //
    //
    draw_router(ctx,offset,xpos,ypos,size,hexcolor,icon_color,border_color,zoom_scale){

        //
        //
        let x = offset.x + xpos
        let y = offset.y + ypos

        //
        // outer-diamond (border)
        this.draw_diamond(
            ctx,
            x,
            y,
            size + 10,
            border_color,
            zoom_scale
        )

        //
        //
        this.draw_diamond(
            ctx,
            x,
            y,
            size,
            hexcolor,
            zoom_scale
        )

        //
        //
        this.draw_text(
            ctx,
            '+',
            size * 1.3,
            //x - 30 * this.zoom_scale,
            x,
            y + 5,
            icon_color,
            100,
            zoom_scale
        )

    }

    //
    //
    draw_filter(ctx,offset,xpos,ypos,size,hexcolor,border_color,zoom_scale){

        //
        //
        // let x = this.compute_xpos(xpos,offset,zoom_scale)
        // let y = this.compute_ypos(ypos,offset,zoom_scale)
        let x = offset.x + xpos
        let y = offset.y + ypos

        //
        // outer funnel (border)
        // this.draw_funnel(
        //     ctx,
        //     x,
        //     y,
        //     size,
        //     10,
        //     border_color,
        //     zoom_scale
        // )

        //
        // outer funnel (border)
        this.draw_trapezoid(
            ctx,
            x,
            y,
            size + 10,
            border_color,
            zoom_scale
        )

        //
        //
        // this.draw_funnel(
        //     ctx,
        //     x,
        //     y,
        //     size,
        //     0,
        //     hexcolor,
        //     zoom_scale
        // )

        //
        //
        this.draw_trapezoid(
            ctx,
            x,
            y,
            size,
            hexcolor,
            zoom_scale
        )

        //
        //
        this.draw_text(
            ctx,
            '+',
            size * 0.9,
            x,
            y,
            '#ffffff',
            100, // max width
            zoom_scale
        )

    }

    // ==================================================================
    // ==================================================================
    // create shapes
    // ==================================================================
    // ==================================================================

    //
    //
    draw_line(ctx,x1,y1,x2,y2,hexcolor,thickness){

        //
        //
        ctx.beginPath()

        //
        //
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.strokeStyle = hexcolor
        ctx.lineWidth = thickness

        //
        //
        ctx.closePath()
        ctx.stroke();

    }

    //
    //
    draw_text(ctx,_text,font_size,x,y,hexcolor,max_width,zoom_scale){
        this.throwable_var = zoom_scale
        //
        //
        let s = font_size

        //
        //
        ctx.beginPath()
        ctx.font = "" + s + "px Arial";
        ctx.fillStyle = hexcolor
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(
            _text,
            x,
            y,
            max_width
        );

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_circle(ctx,x,y,radius,hexcolor,zoom_scale){
        this.throwable_var = zoom_scale
        //
        //
        ctx.beginPath();
        ctx.fillStyle = hexcolor
        ctx.arc(
            x,  // x
            y,  // y
            radius,   // radius = size
            0,    // starting angle, usually 0
            2 * Math.PI // ending angle, usually 360
        );
        //this.context.stroke();
        ctx.fill()
        ctx.closePath();

    }

    //
    //
    draw_circle_border(ctx,x,y,radius,hexcolor,zoom_scale){

        //
        //
        this.draw_circle(
            ctx,
            x,
            y,
            radius,
            hexcolor,
            zoom_scale
        )

    }

    //
    //
    draw_rectangle(ctx,x,y,w,h,hexcolor,zoom_scale){
        this.throwable_var = zoom_scale
        //
        //
        ctx.beginPath();
        ctx.fillStyle = hexcolor; 
        ctx.rect(
            x,  // x
            y,  // y
            w, // width 
            h,  // height
        );
        ctx.fill()
        ctx.closePath();

    }

    //
    //
    draw_rectangle_border(ctx,x,y,w,h,hexcolor,zoom_scale){

        //
        //
        this.draw_rectangle(
            ctx,
            x - 10,
            y - 10,
            w + 20,
            h + 20,
            hexcolor,
            zoom_scale
        )

    }

    //
    //
    draw_image(ctx,uri,x,y,w,h,zoom_scale){

        //
        // rermoves anti aliasing
        ctx.imageSmoothingEnabled = false

        //
        //
        ctx.beginPath()
        ctx.drawImage(
            uri, 
            x, // xpos
            y,  // y
            w * zoom_scale, // width
            h * zoom_scale // height
        )
        ctx.closePath();

    }

    //
    //
    draw_triangle(ctx,x,y,size,offset_angle,hexcolor,zoom_scale){

        //
        //
        ctx.beginPath();

        //
        // Set start-point
        ctx.moveTo(x,y);
        let p2 = this.get_coord(
            x,
            y,
            size * zoom_scale,
            140 + offset_angle
        )
        let p3 = this.get_coord(
            x,
            y,
            size * zoom_scale,
            220 + offset_angle
        )

        //
        // Set sub-points
        ctx.lineTo(p2.x,p2.y);
        ctx.lineTo(p3.x,p3.y);

        //
        // Set end-point
        ctx.lineTo(x,y);
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_diamond(ctx,x,y,size,hexcolor,zoom_scale){
        this.throwable_var = zoom_scale
        //
        //
        let adjust = size

        //
        //
        ctx.beginPath();

        //
        // set start point from the middle, then clockwise
        ctx.moveTo(x,y-adjust) // top
        ctx.lineTo(x+adjust,y) // right
        ctx.lineTo(x,y+adjust) // bottom
        ctx.lineTo(x-adjust,y) // left
        ctx.lineTo(x,y-adjust) // back to top

        //
        //
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_funnel(ctx,x,y,size,border_size,hexcolor,zoom_scale){
        this.throwable_var = zoom_scale

        //
        //
        ctx.beginPath();

        //
        //
        let coords = this.compute_filter_coords(x,y,size,border_size)

        //
        //
        for (let c = 0; c < coords.length; c++) {
            
            //
            //
            if(c == 0){
                ctx.moveTo(coords[c]['x'],coords[c]['y'])
            }
            else{
                ctx.lineTo(coords[c]['x'],coords[c]['y'])
            }
            
        }

        //
        //
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_trapezoid(ctx,x,y,size,hexcolor,zoom_scale){
        this.throwable_var = zoom_scale

        //
        //
        ctx.beginPath()

        //
        //
        let coords = this.compute_trapezoid_coords(x,y,size,10)

        //
        //
        for (let i = 0; i < coords.length; i++) {
            
            //
            //
            if(i==0){
                ctx.moveTo(coords[i]['x'],coords[i]['y'])
            }
            else {
                ctx.lineTo(coords[i]['x'],coords[i]['y'])
            }
            
        }

        //
        //
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath()

    }

    // ==================================================================
    // ==================================================================
    // position, angle finders
    // ==================================================================
    // ==================================================================

    //
    // single point xpos
    compute_xpos(xpos,offset,zoom_scale){

        //
        // offset + grid position
        return (offset.x * zoom_scale) + (xpos * zoom_scale)
    }

    //
    // single point ypos
    compute_ypos(ypos,offset,zoom_scale){

        //
        // offset + grid  position
        return (offset.y * zoom_scale) + (ypos * zoom_scale)

    }

    //
    // not in use
    compute_cx(xpos,offset,zoom_scale){
        return (offset.x * zoom_scale) - (xpos * zoom_scale)
    }

    //
    // not in use
    compute_cy(ypos,offset,zoom_scale){
        return (offset.y * zoom_scale) - (ypos * zoom_scale)
    }

    //
    // not in use
    compute_rcy(ypos,offset,zoom_scale){
        return (offset.y * zoom_scale) + (ypos * zoom_scale)
    }

    //
    // xpos rectangle center
    compute_sx(offset,xpos,w,zoom_scale){

        //
        //
        return this.compute_xpos(xpos,offset,zoom_scale) - (w / 2)

    }

    //
    // ypos rectangle center
    compute_sy(offset,ypos,h,zoom_scale){

        //
        //
        return this.compute_ypos(ypos,offset,zoom_scale) - (h / 2)

    }

    //
    // returns x,y
    compute_popover_xy(e){

        //
        //
        let w = window.innerWidth
        let h = window.innerHeight
        let _x = 0
        let _y = 0
        let max_x = 0.6
        let max_y = 0.35

        //
        //
        if(e.clientX > w/2) {
            _x = w * max_x
        }
        else {
            _x = e.clientX
        }
        
        //
        //
        if(e.clientY > h/2){
            _y = h * max_y
        }
        else {
            _y = e.clientY
        }

        //
        //
        return { x:_x, y:_y }

    }

    //
    // get the next coordinate == returns { x:0, y:0 }
    get_coord(x,y,length,_angle){

        let xpos = Math.cos(
            _angle * Math.PI / 180
        ) * length + x

        let ypos = Math.sin(
            _angle * Math.PI / 180
        ) * length + y

        return {
            'x': xpos,
            'y': ypos
        }

    }

    //
    // get angle (360) between 2 coords
    get_angle(x1,y1,x2,y2){

        return Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI
        
    }

    //
    //
    get_distance(x1,y1,x2,y2){

        //
        //
        let a = x1 - x2
        let b = y1 - y2

        return Math.sqrt( a*a + b*b )

    }

    //
    //
    get_mouse_distance(e,objx,objy){

        //
        //
        let r = this.get_distance(
            e.offsetX,
            e.offsetY,
            objx,
            objy,
        )

        //
        //
        return r

    }

    //
    // not in use
    get_mouse_pos(canvas,e){

        //
        //
        var rect = canvas.getBoundingClientRect() // abs. size of element

        //
        //
        let scaleX = canvas.width / rect.width    // relationship bitmap vs. element for x
        let scaleY = canvas.height / rect.height  // relationship bitmap vs. element for y

        //
        //
        return {
            x: (e.clientX - rect.left) * scaleX,   // scale mouse coordinates after they have
            y: (e.clientY - rect.top) * scaleY     // been adjusted to be relative to element
        }

    }

    //
    // calculate router-child positions, returns array
    compute_router_nodes(xpos,ypos,nodes,movement,offset,zoom_scale){

        //
        //
        let r = []

        //
        // returns array of coordinates
        let x = xpos
        // let x = this.compute_xpos(
        //     xpos,
        //     offset,
        //     zoom_scale
        // )
        let y = ypos
        // let y = this.compute_ypos(
        //     ypos,
        //     offset,
        //     zoom_scale
        // )

        this.throwable_var = offset
        this.throwable_var = zoom_scale

        //
        //
        if(nodes == 1){
            
            //
            //
            r[0] = {
                'x': x,
                'y': y + movement
            }

            //
            //
            return r
        }

        //
        // identify if nodes is even or odd
        if( (nodes % 2) == 1 ){

            //
            // if odd, 1 middle node then divide evenly on the side

            //
            // if 3, space them evenly by movement
            if(nodes >= 3){

                //
                // if 3, then 1 -- if 5, then 2 -- if 7, then 3
                let multiplier = Math.floor( nodes / 2 )
                let s = []

                //
                //
                for (let i = multiplier; i >= 1; i--) {

                    s.push({
                        x: x - ( ( movement * i ) * 1.5 ),
                        y: y + movement,
                    })
                    
                }

                //
                // middle
                s.push({
                    x: x,
                    y: y + movement,
                })


                //
                //
                for (let i = 1; i <= multiplier; i++) {

                    s.push({
                        x: x + ( ( movement * i ) * 1.5 ),
                        y: y + movement,
                    })
                    
                }

                //
                //
                r = s

            }
            
        }
        else {

            //
            // if even, no node in the center

            //
            // if 2, get center, then go right/left divide in half
            if(nodes >= 2){
                
                //
                //
                let multiplier = (nodes / 2) - 1
                let s = []

                //
                // add points from left going to center
                for (let i = multiplier; i >= 0; i--) {

                    //
                    //
                    s.push({
                        'x': x - (movement * 0.75) - ((movement * i) * 1.5),
                        'y': y + movement,
                    })

                }
                
                //
                // add points from center going to right
                for (let i = 0; i <= multiplier; i++) {
                    
                    //
                    //
                    s.push({
                        'x': x + (movement * 0.75) + ((movement * i) * 1.5),
                        'y': y + movement,
                    })

                }

                //
                //
                r = s

            }
        }

        //
        //
        return r

    }

    //
    // returns array of ids with its corresponding index
    id_to_index_array(canvas_data){

        //
        //
        let r = {
            'ids': {}, // index=>id -------- if index is used
            'indices': {}, // id=>index ----- if id is used
            'child': {}, // id as key, child modules id array -- id is used
            //'object_before': {}, // key is the item containing object_before
        }

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {
            
            //
            //
            r['ids'][i] = canvas_data[i].id
            r['indices'][canvas_data[i].id] = i

            //
            //
            if('object_before' in canvas_data[i]){

                //
                //
                if(canvas_data[i].object_before in r['child']) {
                    r['child'][canvas_data[i].object_before].push(
                        canvas_data[i].id
                    )
                }
                else {
                    r['child'][canvas_data[i].object_before] = []
                    r['child'][canvas_data[i].object_before].push(
                        canvas_data[i].id
                    )
                }

            }
            
            
        }

        //
        //
        return r

    }

    //
    //
    compute_filter_coords(x,y,size,border_size){

        //
        //
        let r = []

        let adjust = size + border_size
        let half = adjust / 2
        let quarter3 = (adjust * 0.33) * 2
        let half_neck = (adjust / 6) + border_size
        let shorter_tip = quarter3 * 0.75

        //
        r.push({
            'x': x,
            'y': y-(half + border_size)
        })

        //
        r.push({
            'x': x-(half + (border_size*1.75)),
            'y': y-(half + border_size)
        })

        //
        r.push({
            'x': x-half_neck,
            'y': y
        })

        //ctx.lineTo(x-half_neck,y+(quarter3 + border_size)) 

        //
        r.push({
            'x': x-half_neck,
            'y': y+shorter_tip + (border_size/2)
        })

        //
        r.push({
            'x': x+half_neck,
            'y': y+shorter_tip + (border_size/2)
        })

        //
        r.push({
            'x': x+half_neck,
            'y': y
        })

        //
        r.push({
            'x': x+(half + (border_size*1.75)),
            'y': y-(half + border_size)
        })

        //
        r.push({
            'x': x,
            'y': y-(half + border_size)
        })

        //
        //
        return r

    }

    //
    //
    compute_trapezoid_coords(x,y,size,border_size){

        //
        //
        let r = []

        //
        //
        let adjust = size + border_size
        let half = adjust / 2

        // top
        r.push({
            'x': x,
            'y': y-half,
        })

        // top-left
        r.push({
            'x': x-adjust,
            'y': y-half,
        })

        // bottom left
        r.push({
            'x': x-half,
            'y': y+half,
        })

        // bottom right
        r.push({
            'x': x+half,
            'y': y+half,
        })

        // top right
        r.push({
            'x': x+adjust,
            'y': y-half,
        })

        // top
        r.push({
            'x': x,
            'y': y-half,
        })

        //
        return r

    }

    // ==================================================================
    // ==================================================================
    // canvas data
    // ==================================================================
    // ==================================================================

    //
    //
    get_largest_id(canvas_data){

        //
        //
        let largest_id = 0

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {
            
            //
            //
            if(canvas_data[i].id > largest_id){

                //
                //
                largest_id = canvas_data[i].id

            }
            else if(canvas_data[i].id == 0){

                //
                //
                largest_id = 1

            }

        }

        //
        //
        return largest_id

    }

    //
    //
    get_module(canvas_data,id){

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {

            //
            //
            if(canvas_data[i]['id'] == id){
                return canvas_data[i]
            }
            
        }

    }

    //
    //
    get_module_index(canvas_data,id){

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {
            
            //
            //
            if(canvas_data[i].id == id){
                return i
            }
            
        }

        //
        //
        return false

    }

    //
    // returns {'canvas_data': canvas_data,'id': largest_id}
    add_module(canvas_data,obj,old_id){

        //
        // get the current largest id
        let largest_id = this.get_largest_id(canvas_data)+0

        //
        //
        let _ind = this.get_module_index(canvas_data,old_id)

        //
        //
        obj.id = largest_id
        //obj.ypos = obj.ypos + 250
        canvas_data[_ind] = obj

        //
        //
        return {
            'canvas_data': canvas_data,
            'id': largest_id
        }

    }

    //
    // returns {'canvas_data': canvas_data,'id': largest_id}
    add_empty_module(canvas_data,obj_id){

        //
        // get the current largest id
        let largest_id = this.get_largest_id(canvas_data)+1

        //
        //
        let coord = this.get_module(canvas_data,obj_id)

        //
        //
        let obj = {
            'id': largest_id,
            'object_type': 'empty',
            'object_before': 0,
            'xpos': coord.xpos, // from center
            'ypos': coord.ypos + 250, // from center
            'size': 70,
            'color': '#24695c',
            'border_color': '#333333',
            'hover_border_color': '#348c7c',
            'nonhover_border_color': '#333333',
        }

        //
        //
        canvas_data.push(obj)

        //
        //
        return {
            'canvas_data': canvas_data,
            'id': largest_id
        }

    }

    //
    // id is old id
    update_module(canvas_data,new_obj,id){

        //
        //
        let _index = this.get_module_index(canvas_data,id)

        //
        //
        new_obj.id = this.get_largest_id(canvas_data)+0

        //
        //
        canvas_data[_index] = new_obj

        //
        //
        return canvas_data

    }

    //
    //
    remove_module(canvas_data,id){

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {


            //
            //
            if(canvas_data[i].id == id){
                
                //
                //
                canvas_data.splice(i, 1)
            }
            
        }

        //
        //
        return canvas_data

    }

    //
    //
    get_module_child(canvas_data,parent_id){

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {
            
            //
            //
            if('object_before' in canvas_data[i]){
                if(canvas_data[i]['object_before'] == parent_id){
                    return canvas_data[i]
                }
            }
            
        }

        //
        //
        return false

    }

    //
    // count non-router module that has a router as parent, returns number
    count_router_child(canvas_data,reference){

        //
        //
        let count = 0

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {

            //
            //
            if('object_before' in canvas_data[i]) {

                //
                //
                if('object_type' in canvas_data[i] && canvas_data[i].object_type!='router'){

                    //
                    //
                    let index_before = reference['indices'][
                        canvas_data[i].object_before
                    ]
                    let obj_before = canvas_data[index_before]

                    //
                    //
                    if(obj_before!=undefined || obj_before!=null){

                        //
                        //
                        if('object_type' in obj_before && obj_before.object_type == 'router'){
                            count+=1
                        }

                    }

                }

            }
            
        }

        //
        //
        return count

    }

    //
    // returns array
    get_router_child(reference,obj_id){

        //
        //
        let childs = false

        //
        //
        if( obj_id in reference['child'] ) {
            childs = reference['child'][obj_id]
        }

        //
        return childs

    }

    //
    //
    ordered_routers(canvas_data,reference){

        //
        //
        let childs = reference['child']
        let r = []

        //
        //
        for (let key in childs){

            //
            // returns array
            let routes = this.get_ordered_routers(
                canvas_data,
                reference,
                key,            // current_id
                0,              // starting recursion level
                50              // max level
            )

            //
            //
            for (let i = 0; i < routes.length; i++) {
                
                //
                //
                if(r.includes(routes[i])==false){
                    r.push(routes[i])
                }
                
            }

        }

        //
        //
        return r

    }

    //
    // recursive, returns routes array
    get_ordered_routers(canvas_data,reference,current_id,starting_level,max_level){

        //
        //
        let childs = reference['child']
        let current_level = starting_level
        let r = []
        
        this.throwable_var = current_level
        this.throwable_var = max_level

        //
        // if id does not have childs
        if(childs[current_id] == undefined){
            return r
        }
        
        //
        //
        for (let i = 0; i < childs[current_id].length; i++) {

            //
            //
            let obj_id = childs[current_id][i]
            let obj = canvas_data[
                reference['indices'][obj_id]
            ]

            //
            //
            if(obj.object_type == 'router'){

                //
                //
                r.push(obj.id)

                //
                //
                if(current_level < max_level){

                    //
                    //
                    current_level+=1

                    //
                    //
                    let x = this.get_ordered_routers(
                        canvas_data,
                        reference,
                        obj.id,
                        current_level,
                        max_level
                    )

                    //
                    //
                    r = r.concat(x)

                }

            }
            
        }

        //
        //
        return r

    }

    //
    //
    get_first_router(canvas_data){

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {
            
            //
            //
            if(canvas_data[i].object_type=='router'){
                return canvas_data[i].id
            }
            
        }

        //
        //
        return false // means theres no router

    }

    // ==================================================================
    // ==================================================================
    // detectors - do not remove yet
    // ==================================================================
    // ==================================================================

    //
    // object_id connector is attached to
    detect_hover_connector(e,canvas_data,object_id,offset,zoom_scale){

        //
        //
        let connector_size = 20
        let obj = this.get_module(canvas_data,object_id)

        //
        //
        let xpos = this.compute_xpos(
            obj.xpos,
            offset,
            zoom_scale
        )
        let ypos = this.compute_ypos(
            obj.ypos,
            offset,
            zoom_scale
        )

        //
        //
        let d = this.get_mouse_distance(
            e,
            xpos,
            ypos + ( obj.size * zoom_scale )
        )

        //
        //
        if( d < (connector_size * zoom_scale) ) {
            return true
        }
        else {
            return false
        }

    }

    //
    //
    detectall_hover_connector(e,canvas_data,connector_points,offset,zoom_scale){
  
        //
        //
        for (let i = 0; i < Object.keys(connector_points).length; i++) {
         
            //
            //
            let detector = this.detect_hover_connector(
                e,
                canvas_data,
                connector_points[i].object_id,
                offset,
                zoom_scale
            )

            //
            //
            if(detector == true){

                //
                //
                document.body.style.cursor = "pointer"
                connector_points[i].hover = true
                return;

            }
            else {

                //
                //
                document.body.style.cursor = "context-menu"
                connector_points[i].hover = false

            }
            
        }

        //
        //
        return connector_points

    }
 

}

//
// find ids, indexes, coordinates - not in use
//
//
export class workflow_finder {

    //
    //


}

//
// only draw functions are incldued here
//
//
export class workflow_drawer {

    //
    //
    constructor(){

        //
        //
        this.throwable_var = ''
    }

    // ==================================================================
    // ==================================================================
    // create objects
    // ==================================================================
    // ==================================================================

    //
    //
    //
    //
    draw_gridlines(cvs,ctx,offset,zoom_scale,grid_size,grid_color,grid_center_line_color){

        //
        //
        //let grid_size = 20
        let w = cvs.clientWidth * 10
        let h = cvs.clientHeight * 10

        //
        //
        if(grid_size==0 || grid_size=='' || grid_size==undefined || grid_size==null){
            grid_size=20
        }

        //
        // draw lines to the right
        for (
            let x = offset.x; 
            x < w; 
            x += grid_size * zoom_scale
        ) {
            this.draw_line(ctx,x,0,x,h,grid_color,1)
        }

        //
        // draw lines to the left
        for (
            let x = offset.x; 
            x > 0; 
            x -= grid_size * zoom_scale
        ) {
            this.draw_line(ctx,x,0,x,h,grid_color,1)
        }
        
        //
        // draw lines to the top
        for (
            let y = offset.y; 
            y < h; 
            y += grid_size * zoom_scale
        ) {
            this.draw_line(ctx,0,y,w,y,grid_color,1)
        }
        
        //
        // draw lines to the bottom
        for (
            let y = offset.y; 
            y > 0; 
            y -= grid_size * zoom_scale
        ) {
            this.draw_line(ctx,0,y,w,y,grid_color,1)
        }

        //
        //
        this.draw_line(ctx,offset.x,0,offset.x,h,grid_center_line_color,1)
        this.draw_line(ctx,0,offset.y,w,offset.y,grid_center_line_color,1)

    }

    //
    // circle is drawn from the center, unline rectangle
    //
    //
    draw_origin(ctx,offset,xpos,ypos,size,_color,_border_color){

        //
        //
        // let x = this.compute_xpos(xpos,offset)
        // let y = this.compute_ypos(ypos,offset)
        let x = offset.x + xpos
        let y = offset.y + ypos
        let font_size = 30

        //
        // outer-circle
        this.draw_circle(
            ctx,
            x,
            y,
            size + 10,
            _border_color
        )

        //
        // inner-circle
        this.draw_circle(
            ctx,
            x,
            y,
            size,
            _color
        )

        //
        //
        this.draw_text(
            ctx,
            'Select a module',
            font_size,
            //x - ( (size * 1.30) * this.zoom_scale),
            x,
            y - (size + font_size),
            '#000000',
            200
        )

        //
        //
        this.draw_text(
            ctx,
            '+',
            100,
            //x - 30 * this.zoom_scale,
            x,
            y + 5,
            '#ffffff',
            150
        )

    }

    //
    //
    //
    //
    draw_trigger(ctx,offset,xpos,ypos,size,_text,_color,text_color,icon_color,_border_color){

        //
        //
        let x = offset.x + xpos
        let y = offset.y + ypos
        let font_size = 24

        //
        //
        let display_text = ''
        if(_text=='' || _text==undefined){
            display_text = 'empty'
        }
        else {
            display_text = _text.split('. ')[1]
        }

        //
        // outer-circle (border)
        this.draw_circle(
            ctx,
            x,
            y,
            size + 10,
            _border_color
        )

        //
        // inner-circle
        this.draw_circle(
            ctx,
            x,
            y,
            size,
            _color
        )

        //
        // draw text above
        this.draw_text(
            ctx,
            _text,
            font_size,
            x, // center
            y - (size + font_size), // above circle
            text_color,
            500
        )

        //
        // letters - initials (icon color)
        this.draw_text(
            ctx,
            display_text[0],
            80, // fontsize
            x,
            y + 5,
            icon_color,
            150
        )

    }

    //
    //
    draw_empty_module(ctx,offset,xpos,ypos){
        
        //
        //
        let centerx = offset.x + xpos
        let centery = offset.y + ypos

        //
        //
        let w = 300
        let h = 100

        //
        //
        let movex = (w / 2)
        let movey = (h / 2)
        
        
        //
        //
        this.draw_rectangle(
            ctx,
            centerx - movex,
            centery - movey,
            w,
            h,
            '#000000'
        )

        //
        // draw module name
        this.draw_text(
            ctx,
            '+', // name
            100, // font size
            centerx ,
            centery,
            '#ffffff',
            w
        )


    }

    //
    // x & y should be the center of the rectangle like on the circle
    draw_module(ctx,obj_id,offset,xpos,ypos,module_name,title,text_color,color,secondary_text_color,secondary_color,border_color){

        //
        //
        let centerx = offset.x + xpos
        let centery = offset.y + ypos

        //
        //
        let w = 300
        let h = 100

        //
        //
        let movex = (w / 2)
        let movey = (h / 2)

        //
        // draw border
        this.draw_rectangle(
            ctx,
            (centerx - movex) - 10,
            (centery - movey) - 10,
            w + 20,
            h + 20,
            border_color
        )

        //
        // draw header
        this.draw_rectangle(
            ctx,
            centerx - movex,
            centery - movey,
            w,
            h/2,
            color
        )

        //
        // draw module name
        this.draw_text(
            ctx,
            obj_id + '. ' + module_name,
            20, // font size
            centerx,
            centery - 25,
            text_color,
            w
        )

        //
        // draw body
        this.draw_rectangle(
            ctx,
            centerx - movex,
            centery,
            w,
            h/2,
            secondary_color
        )

        //
        // draw title
        this.draw_text(
            ctx,
            title,
            20, // font size
            centerx,
            centery + 25,
            secondary_text_color,
            w
        )

    }

    //
    //
    draw_connector_point(ctx,offset,xpos,ypos,size,zoom_scale,show_plus){

        //
        //
        let x = offset.x + xpos
        let y = offset.y + ypos

        //
        //
        this.draw_circle(
            ctx,
            x,
            y,
            size,
            '#000000',
            zoom_scale
        )

        //
        //
        if(show_plus==true){

            //
            //
            this.draw_text(
                ctx,
                '+',
                50, // font size
                //x - 30 * this.zoom_scale,
                x,
                y + 5,
                '#ffffff',
                50,
                zoom_scale
            )

        }

    }

    //
    // not in use
    draw_connection(ctx,offset,obj1,obj2,hexcolor,zoom_scale){
        this.throwable_var = zoom_scale
        //
        // //
        // this.draw_line(
        //     ctx,
        //     offset.x + obj1.xpos,
        //     offset.y + obj1.ypos,
        //     offset.x + obj2.xpos,
        //     offset.y + obj2.ypos,
        //     // this.compute_xpos(obj1.xpos,offset,zoom_scale),
        //     // this.compute_ypos(obj1.ypos,offset,zoom_scale),
        //     // this.compute_xpos(obj2.xpos,offset,zoom_scale),
        //     // this.compute_ypos(obj2.ypos,offset,zoom_scale),
        //     hexcolor,
        //     20
        // )

    }

    //
    //
    draw_router(ctx,offset,xpos,ypos,size,hexcolor,icon_color,border_color,zoom_scale){

        //
        //
        let x = offset.x + xpos
        let y = offset.y + ypos

        //
        // outer-diamond (border)
        this.draw_diamond(
            ctx,
            x,
            y,
            size + 10,
            border_color,
            zoom_scale
        )

        //
        //
        this.draw_diamond(
            ctx,
            x,
            y,
            size,
            hexcolor,
            zoom_scale
        )

        //
        //
        this.draw_text(
            ctx,
            '+',
            size * 1.3,
            //x - 30 * this.zoom_scale,
            x,
            y + 5,
            icon_color,
            100,
            zoom_scale
        )

    }

    //
    //
    draw_filter(ctx,offset,xpos,ypos,size,hexcolor,border_color,zoom_scale){

        //
        //
        // let x = this.compute_xpos(xpos,offset,zoom_scale)
        // let y = this.compute_ypos(ypos,offset,zoom_scale)
        let x = offset.x + xpos
        let y = offset.y + ypos

        //
        // outer funnel (border)
        // this.draw_funnel(
        //     ctx,
        //     x,
        //     y,
        //     size,
        //     10,
        //     border_color,
        //     zoom_scale
        // )

        //
        // outer funnel (border)
        this.draw_trapezoid(
            ctx,
            x,
            y,
            size + 10,
            border_color,
            zoom_scale
        )

        //
        //
        // this.draw_funnel(
        //     ctx,
        //     x,
        //     y,
        //     size,
        //     0,
        //     hexcolor,
        //     zoom_scale
        // )

        //
        //
        this.draw_trapezoid(
            ctx,
            x,
            y,
            size,
            hexcolor,
            zoom_scale
        )

        //
        //
        this.draw_text(
            ctx,
            '+',
            size * 0.9,
            x,
            y,
            '#ffffff',
            100, // max width
            zoom_scale
        )

    }

    // ==================================================================
    // ==================================================================
    // create shapes
    // ==================================================================
    // ==================================================================

    //
    //
    draw_line(ctx,x1,y1,x2,y2,hexcolor,thickness){

        //
        //
        ctx.beginPath()

        //
        //
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.strokeStyle = hexcolor
        ctx.lineWidth = thickness

        //
        //
        ctx.closePath()
        ctx.stroke();

    }

    //
    //
    draw_text(ctx,_text,font_size,x,y,hexcolor,max_width){

        //
        //
        let s = font_size

        //
        //
        ctx.beginPath()
        ctx.font = "" + s + "px Arial";
        ctx.fillStyle = hexcolor
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(
            _text,
            x,
            y,
            max_width
        );

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_circle(ctx,x,y,radius,hexcolor){

        //
        //
        ctx.beginPath();
        ctx.fillStyle = hexcolor
        ctx.arc(
            x,  // x
            y,  // y
            radius,   // radius = size
            0,    // starting angle, usually 0
            2 * Math.PI // ending angle, usually 360
        );
        //this.context.stroke();
        ctx.fill()
        ctx.closePath();

    }

    //
    //
    draw_rectangle(ctx,x,y,w,h,hexcolor){

        //
        //
        ctx.beginPath();
        ctx.fillStyle = hexcolor; 
        ctx.rect(
            x,  // x
            y,  // y
            w, // width 
            h,  // height
        );
        ctx.fill()
        ctx.closePath();

    }

    //
    //
    draw_image(ctx,uri,x,y,w,h){

        //
        // rermoves anti aliasing
        ctx.imageSmoothingEnabled = false

        //
        //
        ctx.beginPath()
        ctx.drawImage(
            uri, 
            x, // xpos
            y,  // y
            w, // width
            h // height
        )
        ctx.closePath();

    }

    //
    //
    draw_triangle(ctx,x,y,size,offset_angle,hexcolor){

        //
        //
        ctx.beginPath();

        //
        // Set start-point
        ctx.moveTo(x,y);
        let p2 = this.get_coord(
            x,
            y,
            size,
            140 + offset_angle
        )
        let p3 = this.get_coord(
            x,
            y,
            size,
            220 + offset_angle
        )

        //
        // Set sub-points
        ctx.lineTo(p2.x,p2.y);
        ctx.lineTo(p3.x,p3.y);

        //
        // Set end-point
        ctx.lineTo(x,y);
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_diamond(ctx,x,y,size,hexcolor){

        //
        //
        let adjust = size

        //
        //
        ctx.beginPath();

        //
        // set start point from the middle, then clockwise
        ctx.moveTo(x,y-adjust) // top
        ctx.lineTo(x+adjust,y) // right
        ctx.lineTo(x,y+adjust) // bottom
        ctx.lineTo(x-adjust,y) // left
        ctx.lineTo(x,y-adjust) // back to top

        //
        //
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_funnel(ctx,x,y,size,border_size,hexcolor){

        //
        //
        ctx.beginPath();

        //
        //
        let coords = this.compute_filter_coords(x,y,size,border_size)

        //
        //
        for (let c = 0; c < coords.length; c++) {
            
            //
            //
            if(c == 0){
                ctx.moveTo(coords[c]['x'],coords[c]['y'])
            }
            else{
                ctx.lineTo(coords[c]['x'],coords[c]['y'])
            }
            
        }

        //
        //
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath();

    }

    //
    //
    draw_trapezoid(ctx,x,y,size,hexcolor){

        //
        //
        ctx.beginPath()

        //
        //
        let coords = this.compute_trapezoid_coords(x,y,size,10)

        //
        //
        for (let i = 0; i < coords.length; i++) {
            
            //
            //
            if(i==0){
                ctx.moveTo(coords[i]['x'],coords[i]['y'])
            }
            else {
                ctx.lineTo(coords[i]['x'],coords[i]['y'])
            }
            
        }

        //
        //
        ctx.fillStyle = hexcolor
        ctx.fill()

        //
        //
        ctx.closePath()

    }
    
}

//
// realign algo
//
//
export class workflow_realign {

    //
    // returns array of ids with its corresponding index
    id_to_index_array(canvas_data){

        //
        //
        let r = {
            'ids': {}, // index=>id -------- if index is used
            'indices': {}, // id=>index ----- if id is used
            'child': {}, // id as key, child modules id array -- id is used
            //'object_before': {}, // key is the item containing object_before
        }

        //
        //
        for (let i = 0; i < canvas_data.length; i++) {
            
            //
            //
            r['ids'][i] = canvas_data[i].id
            r['indices'][canvas_data[i].id] = i

            //
            //
            if('object_before' in canvas_data[i]){

                //
                //
                if(canvas_data[i].object_before in r['child']) {
                    r['child'][canvas_data[i].object_before].push(
                        canvas_data[i].id
                    )
                }
                else {
                    r['child'][canvas_data[i].object_before] = []
                    r['child'][canvas_data[i].object_before].push(
                        canvas_data[i].id
                    )
                }

            }
            
            
        }

        //
        //
        return r

    }

    //
    //


}


//
//
export const init_workflow_helper = function(){

    //
    //
    return new workflow_class()

}

// =======================================================================

/*
*
*
* 
* 
* 
*/
export class workflow_filter_operators {

    /*
        Exists
        Does not exists
        Equals
        Not equal to
        Contains
        Does not contain
        Starts with
        Does not start with
        Ends with
        Does not end with
        Greater than
        Less than
        Greater than or equal to
        Less than or equal to
        Later than
        Earlier than
        Is an array
        Is not an array
        Array length equal to
        Array length not equal to
        Array length grater than
        Array length less than
        Array length greater than or equal to
        Array Length less than or equal to
    */
    constructor(first_value,operator,last_value){

        //
        //
        this.string_operator == operator
        this.value1 = first_value
        this.value2 = last_value

    }

    //
    //
    exists(v){

        //
        //
        if(v == undefined) {
            return false
        }
        else if(v == null) {
            return false
        }
        else {
            return true
        }

    }

    //
    //
    does_not_exists(v){

        //
        //
        return !this.exists(v)

    }

    //
    //
    equals(v1,v2){

        //
        //
        if(v1==v2){
            return true
        }
        else {
            return false
        }

    }
    
    //
    //
    not_equal_to(v1,v2){

        //
        //
        return !this.equals(v1,v2)

    }

    //
    //
    contains(v1,v2){

        //
        //
        return v1.includes(v2)

    }

    //
    //
    does_not_contain(v1,v2){

        //
        //
        return !this.contains(v1,v2)

    }

    //
    //
    starts_with(v1,v2){

        //
        //
        return v1.startsWith(v2)

    }

    //
    //
    does_not_start_with(v1,v2){

        //
        //
        return !this.starts_with(v1,v2)

    }

    //
    //
    ends_with(v1,v2){

        //
        //
        return v1.endsWith(v2)

    }

    //
    //
    does_not_end_with(v1,v2){

        //
        //
        return !this.ends_with(v1,v2)

    }

    //
    //
    greater_than(v1,v2){

        //
        //
        if( Number(v1) > Number(v2) ){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    less_than(v1,v2){

        //
        //
        if( Number(v1) < Number(v2) ){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    greater_than_or_equal_to(v1,v2){

        //
        //
        if( Number(v1) >= Number(v2) ){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    less_than_or_equal_to(v1,v2){

        //
        //
        if( Number(v1) <= Number(v2) ){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    later_than(v1,v2){

        //
        // these 2 dates needs to have the same format
        // format can be : MM/DD/YYYY or dash, or YYYY/MM/DD
        let dt = new Date()
        v1 = dt.parse(v1)
        v2 = dt.parse(v2)

        //
        //
        if(v1 > v2){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    earlier_than(v1,v2){

        //
        //
        return !this.later_than(v1,v2)

    }

    //
    //
    is_an_array(v1){

        //
        //
        if(Array.isArray(v1)==true){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    is_not_an_array(v1){

        //
        //
        return !this.is_an_array(v1)

    }

    //
    //
    array_length_equal_to(v1,v2){

        //
        //
        if(v1.length == v2){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    array_length_not_equal_to(v1,v2){

        //
        //
        return !this.array_length_equal_to(v1,v2)

    }

    //
    //
    array_length_greater_than(v1,v2){

        //
        //
        if(v1.length > Number(v2)){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    array_length_less_than(v1,v2){

        //
        //
        if(v1.length < Number(v2)){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    array_length_greater_than_or_equal_to(v1,v2){

        //
        //
        if(v1.length >= Number(v2)){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    array_length_less_than_or_equal_to(v1,v2){

        //
        //
        if(v1.length <= Number(v2)){
            return true
        }
        else {
            return false
        }

    }

    //
    //
    //
    //
    result(){

        //
        //
        let so = this.string_operator
        let v1 = this.value1
        let v2 = this.value2

        //
        //
        if(so == 'Exists'){
            return this.exists(v1)
        }
        else if(so == 'Does not exists'){
            return this.does_not_exists(v1)
        }
        else if(so == 'Equal'){
            return this.equals(v1,v2)
        }
        else if(so == 'Not equal to'){
            return this.not_equal_to(v1,v2)
        }
        else if(so == 'Contains'){
            return this.contains(v1,v2)
        }
        else if(so == 'Does not contain'){
            return this.does_not_contain(v1,v2)
        }
        else if(so == 'Starts with'){
            return this.starts_with(v1,v2)
        }
        else if(so == 'Does not start with'){
            return this.does_not_start_with(v1,v2)
        }
        else if(so == 'Ends with'){
            return this.ends_with(v1,v2)
        }
        else if(so == 'Does not end with'){
            return this.does_not_end_with(v1,v2)
        }
        else if(so == 'Greater than'){
            return this.greater_than(v1,v2)
        }
        else if(so == 'Less than'){
            return this.less_than(v1,v2)
        }
        else if(so == 'Greater than or equal to'){
            return this.greater_than_or_equal_to(v1,v2)
        }
        else if(so == 'Less than or equal to'){
            return this.less_than_or_equal_to(v1,v2)
        }
        else if(so == 'Later than'){
            return this.later_than(v1,v2)
        }
        else if(so == 'Earlier than'){
            return this.earlier_than(v1,v2)
        }
        else if(so == 'Is an array'){
            return this.is_an_array(v1)
        }
        else if(so == 'Is not an array'){
            return this.is_not_an_array(v1)
        }
        else if(so == 'Array length equal to'){
            return this.array_length_equal_to(v1,v2)
        }
        else if(so == 'Array length not equal to'){
            return this.array_length_not_equal_to(v1,v2)
        }
        else if(so == 'Array length greater than'){
            return this.array_length_greater_than(v1,v2)
        }
        else if(so == 'Array length less than'){
            return this.array_length_less_than(v1,v2)
        }
        else if(so == 'Array length greater than or equal to'){
            return this.array_length_greater_than_or_equal_to(v1,v2)
        }
        else if(so == 'Array length less than or equal to'){
            return this.array_length_less_than_or_equal_to(v1,v2)
        }
        
    }

}

//
//
export const init_workflow_filter_operators = function(v1,operator,v2){

    //
    //
    let x = new workflow_filter_operators(v1,operator,v2)

    //
    //
    return x.result()

}

/*
*
*
* 
* 
* 
*/
export class class_workflow_data_processor {

    //
    //
    constructor(canvas_data){
        
        //
        //
        this.data = canvas_data
        this.index = {}

        //
        //
        this.index_data()

    }

    //
    //
    index_data(){

        //
        //
        for (let i = 0; i < this.data.length; i++) {
            
            this.index[ this.data[i].id ] = i
            
        }

    }

    //
    //
    get_data(id){

        //
        //
        return this.data[ this.index[id] ]

    }

    // ======================================================

    //
    //
    is_object(obj){

        //
        //
        try {

            //
            //
            if(obj.constructor === Object){
                return true
            }
            return false 
            
        } catch (error) {
            
            return false

        }

    }

    //
    //
    is_array(arr){

        //
        //
        try {

            return Array.isArray(arr) // true or false

        } catch (error) {
            
            return false

        }

    }

    // ======================================================

    //
    //
    format_bracket(str_dict) {

        //
        //
        let r = ''
        r = str_dict.join('. ')
        r = '[[' + r + ']]'

        //
        //
        return r

    }

    //
    //
    unformat_bracket(str){

        //
        //
        // needs regex for improvement of this one

        //
        //
        let r = {}
        let s = str.replace('[[','')
        s = s.replace(']]','')
        let sp = s.split('. ')

        //
        //
        r['id'] = Number(sp[0])
        r['value_key'] = s.replace(sp[0] + '. ','')
        r['value_key'] = r['value_key'].split('.')

        //
        //
        return r

    }

    // ======================================================

    //
    //
    get_value(str_var){

        //
        //
        let s = this.unformat_bracket(str_var)
        let _id = s['id']

        //
        //
        let _data = this.get_data(_id)
        //
        //
        return _data

    }

    //
    //
    match_bracket_values(str){

        //
        //
        let reg = /\[\[(.*?)\]\]/g
        let r = str.match(reg)

        //
        //
        if(r==null||r==[]){
            return []
        }
        else {
            return str.match(reg) // array
        }

    }

    // refactor this
    //
    get_data_value(id,key){

        //
        //
        try {
            
            //
            //
            let i = this.index[id]
            let r = ''

            //
            //
            if(key.length == 1){
                r = this.data[i].output[key[0]]
            }
            else if(key.length == 2){
                r = this.data[i].output[key[0]][key[1]]
            }
            else if(key.length == 3){
                r = this.data[i].output[key[0]][key[1]][key[2]]
            }
            else if(key.length == 4){
                r = this.data[i].output[key[0]][key[1]][key[2]][key[3]]
            }
            else if(key.length == 5){
                r = this.data[i].output[key[0]][key[1]][key[2]][key[3]][key[4]]
            }

            //
            //
            return r

        } catch (error) {

            //
            //
            return undefined
                
        }

    }

    //
    // extract values from bracketed strings [[1. key.subkey]]
    get_output(str_ver){

        //
        //
        let str = str_ver
        let reg_array = this.match_bracket_values(str)
        let new_array = []

        //
        //
        for (let i = 0; i < reg_array.length; i++) {
            
            //
            //
            let _id = this.unformat_bracket(reg_array[i]).id
            let _key = this.unformat_bracket(reg_array[i]).value_key
            let _data = this.get_data_value(_id,_key)

            //
            //
            new_array.push(_data)

        }

        //
        //
        if( this.is_array(new_array[0]) ){

            //
            //
            str = new_array[0]

        }
        else if( this.is_object(new_array[0]) ){

            //
            //
            let r1 = {}

            //
            //
            for( var _key1 of Object.keys(new_array[0]) ){
                r1[_key1] = new_array[0][_key1]
            }

            //
            //
            str = r1

        }
        else {

            //
            //
            for (let i = 0; i < reg_array.length; i++) {
            
                //
                //
                str = str.replace(reg_array[i],new_array[i])
                
            }

        }

        //
        //
        if(str == undefined){
            return str_ver
        }
        else {
            return str
        }

    }

    //
    //
    // returns true or false
    // verify if str_ver [[1.data.sub]] exists in canvas_data
    verify_output(str_var){

        //
        //
        let reg_array = this.match_bracket_values(str_var)[0]

        //
        //
        if(reg_array!=undefined){

            let _id = this.unformat_bracket(reg_array).id
            let _key = this.unformat_bracket(reg_array).value_key
            let _data = this.get_data_value(_id,_key)

            //
            //
            if(_data==undefined){
                return false
            }
            else {
                return true
            }

        }
        else {

            return false

        }

    }

    // ==========================================================

    //
    //
    clean_filters(filter_array,prefield){

        //
        //
        let r = []

        //
        //
        for (let i = 0; i < filter_array.length; i++) {

            //
            //
            let f1 = filter_array[i]
            let r2 = []

            //
            //
            for (let i2 = 0; i2 < f1.length; i2++) {
                
                //
                //
                let f2 = f1[i2] // filter_array[i][i2]

                //
                //
                let fi = ''
                
                //
                //
                if(prefield==undefined||prefield==null||prefield==false){
                    fi = this.get_output(f2.field_name)
                }
                else if(prefield==true) {
                    fi = this.clean_field_value(f2.field_name)
                }

                //
                //
                r2.push({
                    'field_name': fi,
                    'operator': f2.operator,
                    'value': this.get_output(f2.value)
                })
                
            }

            //
            //
            r.push(r2)
            
        }

        //
        //
        return r

    }

    //
    // not in use
    get_map_value(_map,_array,_str,_key){

        //
        //
        let r = ''
        let _output = ''

        //
        //
        try {

            //
            //
            _output = this.get_output(_str)
            
        } catch (error) {
            
            //
            //
            this.throwable_var = error
            _output = 'missing value'

        }

        //
        //
        if(_output == ''){
            _output = 'missing value'
        }
        
        //
        //
        if(_map==true){
            
            r = _output

        }
        else {

            //
            //
            if(_array[0] == undefined){
                r = []
            }
            else {
                r = _array[0][_key]
            }
            
        }

        //
        //
        return r

    }

    //
    //
    clean_field_value(v){

        //
        //
        let r = v.replace(' - ', '__')
        return r

    }

    //
    //
    clean_fields(unclean_arr){

        //
        //
        let r = []

        //
        //
        for (let i = 0; i < unclean_arr.length; i++) {
            r.push( this.clean_field_value(unclean_arr[i]) )
        }

        //
        //
        return r

    }

    //
    //
    unclean_field_value(v){

        //
        //
        let r = v.replace('__', ' - ')
        return r

    }

    //
    //
    unclean_fields(clean_arr){

        //
        //
        let r = []

        //
        //
        for (let i = 0; i < clean_arr.length; i++) {
            r.push( this.unclean_field_value(clean_arr[i]) )
        }

        //
        //
        return r

    }

    //
    //
    clean_key_values(unclean_arr,_array){

        //
        //
        let r = ''
        if(_array==null || _array==undefined || _array==false){
            r = {}
        }
        else if(_array==true) {
            r = []
        }

        //
        //
        for (let i = 0; i < unclean_arr.length; i++) {

            //
            //
            if(_array==null || _array==undefined || _array==false){

                //
                //
                r[ this.get_output(unclean_arr[i].key) ] = this.get_output(unclean_arr[i].value)

            }
            else if(_array==true) {

                //
                //
                r.push({
                    'key': this.get_output(unclean_arr[i].key),
                    'value': this.get_output(unclean_arr[i].value),
                })

            }

        }

        //
        //
        return r

    }

    // ==========================================================

}

//
//
export const workflow_data_processor = function(canvas_data){

    //
    //
    let x = new class_workflow_data_processor(canvas_data)

    //
    //
    return x

}









