import { createApp } from 'vue'
import app from './app.vue'
import router from './router'
import store from './store'

import posthogPlugin from "./plugins/posthog"; //import the plugin. 

createApp(app).use(store).use(router).use(posthogPlugin).mount('#app')


// posthog
// send manual event
// posthog.capture('my event', { property: 'value' })

