
// ==================================================================
// datetime helpers
// ==================================================================

/*
*
*
* 
* 
* 
*/
export class class_pendulum {

    //
    //
    constructor(){

        // not in use
        //
        this.token = {}

        //
        //
        this.month_name = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'Deceember',
        ]

        //
        //
        this.month_shortname = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ]

        //
        //
        this.month_ordinal = {
            'January': '1st',
            'February': '2nd',
            'March': '3rd',
            'April': '4th',
            'May': '5th',
            'June': '6th',
            'July': '7th',
            'August': '8th',
            'September': '9th',
            'October': '10th',
            'November': '11th',
            'December': '12th',
        }

        //
        //
        this.day_name = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ]

        //
        //
        this.day_shortname = [
            'Sun',
            'Mon',
            'Tue',
            'Wed',
            'Thur',
            'Fri',
            'Sat'
        ]

    }

    //
    //
    format_datetime_value(dt){

        //
        //
        let year = this.get_year(dt)
        let month = this.get_month(dt)
        let day = this.get_day(dt)
        let time = this.get_time(dt)

        //
        //
        let _date = this.get_month_name(month) + ' ' + day + ', ' + year
        //let _date = month + '/' + day + '/' + year
        let _datetime = [_date,time]
        _datetime = _datetime.join(' ')
        return _datetime

    }

    //
    //
    get_ordinal(str){
        
        //
        //
        let _num = str
        let leng = _num.length
        let less1 = ''

        //
        //
        if(leng > 1){
            less1 = leng - 1
        }
        else {
            less1 = leng
        }

        //
        //
        let last_num = _num.substring(less1) // get last character
        last_num = Number(last_num)
        let _append = 'th'

        //
        //
        if(last_num == 1){
            _append = 'st'
        }
        else if(last_num == 2){
            _append = 'nd'
        }
        else if(last_num == 3){
            _append = 'rd'
        }

        //
        //
        return String(_num) + _append

    }

    // =====================================================
    
    // not in use
    //
    get_firstday_year(dt){

        //
        //
        return new Date(new Date(dt).getFullYear(), 0,1)

    }

    //
    //
    get_lastday_year(dt){

        //
        //
        return new Date(new Date(dt).getFullYear(), 11, 31)

    }

    //
    //
    get_year(dt){
     
        //
        //
        let r = new Date(dt)
        return r.getFullYear()

    }

    //
    //
    get_year_short(dt){
     
        //
        //
        let r = new Date(dt)
        return Number( String(r.getFullYear()).substring(2) )

    }

    // =====================================================

    //
    // 1, 2, 3, etc.
    get_month(dt){

        //
        //
        let r = new Date(dt)
        return r.getMonth() + 1

    }

    //
    // January, February, etc.
    get_month_name(m){

        //
        //
        return this.month_name[m-1]

    }

    //
    // Jan, Feb, etc.
    get_month_shortname(m){

        //
        //
        return this.month_shortname[m-1]

    }

    //
    // 01, 02, 11, 12, etc.
    add_zero_months(m){

        //
        //
        let r = ''
        let mx = Number(m)

        //
        //
        if(mx < 10){
            r = '0' + String(mx)
        }

        //
        //
        return r

    }

    //
    //
    get_month_ordinal(dt){

        //
        //
        let month_name = this.get_month_name(this.get_month(dt))
        return this.month_ordinal[month_name]

    }

    // =====================================================

    //
    //
    get_quarter(dt){

        //
        //
        let m = this.get_month(dt)

        //
        //
        if( m >= 1 && m <= 3 ){
            return 1
        }
        else if( m >= 4 && m <= 7 ){
            return 2
        }
        else if( m > 6 && m < 10 ){
            return 3
        }
        else if( m > 9 && m < 13 ){
            return 4
        }

    }
    
    //
    //
    get_quarter_ordinal(dt){

        //
        //
        let q = this.get_quarter(dt)
        return this.get_ordinal(q)

    }

    // =====================================================

    //
    // week number in a year
    get_week_year(dt){

        //
        //
        //let m = this.get_month(dt)
        let now = new Date(dt)
        let jan1 = new Date(now.getFullYear(), 0, 1)

        //
        //
        let w = Math.ceil(
            (
                (
                    ( now.getTime() - jan1.getTime() ) / 86400000
                ) + jan1.getDay() + 1
            ) / 7
        )

        //
        //
        return w

    }

    //
    //
    get_week_year_ordinal(dt){

        //
        //
        let w = this.get_week_year(dt)
        return this.get_ordinal(w)

    }

    //
    //
    get_week_month(dt){

        //
        //
        let d = new Date(dt);
        let date = d.getDate();
        let day = d.getDay();

        //
        //
        return Math.ceil( (date - 1 - day) / 7 );

    }

    //
    //
    get_week_month_ordinal(dt){

        //
        //
        let w = this.get_week_month(dt)
        return this.get_ordinal(w)

    }

    // =====================================================

    //
    // 1, 2, 20, 30 etc.
    get_day(dt){

        //
        //
        let r = new Date(dt)
        return r.getDate()

    }

    //
    //
    get_day_of_week(dt){

        //
        //
        let d = new Date(dt);
        return d.getDay()

    }

    //
    // Sunday, Monday, etc.
    get_day_name(dt){

        //
        //
        let d = new Date(dt);
        d = d.getDay()
        return this.day_name[d]

    }

    //
    // Sun, Mon, etc
    get_day_shortname(dt){

        //
        //
        let d = new Date(dt);
        d = d.getDay()
        return this.day_shortname[d]

    }

    //
    //
    get_day_year_ordinal(dt){
        
        //
        //
        let dy = this.get_day_year(dt)
        return this.get_ordinal(dy)

    }

    //
    //
    get_day_year(dt){

        //
        //
        let now = new Date(dt)
        let start = new Date(now.getFullYear(), 0, 0)
        let diff = now - start
        let oneDay = 1000 * 60 * 60 * 24
        let day = Math.floor(diff / oneDay)
        return String(day)

    }

    //
    // 1st, 2nd, etc.
    get_day_month_ordinal(d){

        // //
        // //
        // let r = ''

        // //
        // //
        // if(d==1 || d==21 || d==31){
        //     r = String(d) + 'st'
        // }
        // else if(d==2 || d==22){
        //     r = String(d) + 'nd'
        // }
        // else if(d==3 || d==23){
        //     r = String(d) + 'rd'
        // }
        // else {
        //     r = String(d) + 'th'
        // }

        // //
        // //
        // return r

        return this.get_ordinal(d)

    }

    //
    //
    get_day_week_ordinal(d){

        //
        //
        return this.get_ordinal(d)

    }

    //
    // 01, 02, 10, 11, etc
    add_zero_days(d){

        //
        //
        let r = ''
        let dx = Number(d)

        //
        //
        if(dx < 10){
            r = '0' + String(dx)
        }
        else {
            r = d
        }

        //
        //
        return String(r)

    }

    // =====================================================

    //
    //
    get_time(dt){

        //
        //
        let r = new Date(dt)
        let hr = r.getHours()
        let mn = r.getMinutes()
        let am_pm = 'am'

        //
        //
        if(hr > 12){
            am_pm = 'pm'
            hr = hr - 12
        }

        //
        //
        if(mn==0){
            mn = '00'
        }
        else if(mn>0 && mn<15){
            mn = '00'
        }
        else if(mn>14 && mn<30){
            mn = 15
        }
        else if(mn>29 && mn<45){
            mn = 30
        }
        else if(mn>44){
            mn = 45
        }

        return hr + ':' + mn + ' ' + am_pm

    }

    //
    // 24 hr format
    get_hour(dt){

        //
        //
        let r = new Date(dt)
        return r.getHours()

    }

    //
    // 24 hr format
    get_hour_zeroes(dt){

        //
        //
        let h = this.get_hour(dt)

        //
        //
        if(h < 10){
            h = '0' + String(h)
        }

        //
        //
        return h

    }

    //
    // 12 hr format
    get_hour12(dt){

        //
        //
        let r = new Date(dt)
        let h = r.getHours()
        if(h>12){
            h = h - 12
        }
        return h

    }

    //
    // 12 hr format with leading zeroes
    get_hour12_zeroes(dt){

        //
        //
        let h = this.get_hour12(dt)

        //
        //
        if(h < 10){
            h = '0' + String(h)
        }

        //
        //
        return h

    }

    //
    //
    get_minute(dt){

        //
        //
        let r = new Date(dt)
        return r.getMinutes()

    }

    //
    //
    get_minute_zeroes(dt){

        //
        //
        let m = this.get_minute(dt)

        //
        //
        if(m < 10){
            m = '0' + String(m)
        }

        //
        //
        return m

    }

    //
    //
    get_seconds(dt){

        //
        //
        let r = new Date(dt)
        return r.getSeconds()

    }

    //
    //
    get_seconds_zeroes(dt){

        //
        //
        let s = this.get_seconds(dt)

        //
        //
        if(s < 10){
            s = '0' + String(s)
        }

        //
        //
        return s

    }

    // =====================================================

    //
    //
    datetime_timestamp(dt){

        //
        //
        let r = new Date(dt)
        return r.getTime() / 1000

    }

    //
    //
    timestamp_datetime(tmp){

        //
        //
        let r = new Date(tmp * 1000)

        //
        //
        let y = r.getFullYear()
        //let m = r.getMonth() + 1
        let m = r.toLocaleString('default', { month: 'long' })
        let d = r.getDate()
        let t = this.get_time(r)

        //
        //
        return m + ' ' + d + ', ' + y + ' ' + t

    }

    //
    //count days betwween 2 datetimes
    count_days(start_dt,end_dt){

        //
        //
        let csd = new Date(start_dt)
        let ced = new Date(end_dt)

        //
        //
        let diff = ced - csd
        let count = diff / (1000 * 60 * 60 * 24)

        //
        //
        return count

    }

    //
    //
    add_days(dt,num_days){

        //
        //
        let r = new Date(dt)
        let _day = 60 * 60 * 24 * 1000
        let s = new Date( r.getTime() + (_day * num_days) )
        return s

    }

    //
    //
    replace_token(pattern,dtx){

        //
        //
        //
        let dt = new Date(dtx)
        let p = pattern

        //
        //
        // hours
        if(p.includes('HH')==true){
            p = p.replace( 'HH', this.get_hour_zeroes(dt) ) // 24 hour format with leading zeroes
        }
        if(p.includes('H')==true){
            p = p.replace( 'H', this.get_hour(dt) ) // 24 hour format
        }
        if(p.includes('hh')==true){
            p = p.replace( 'hh', this.get_hour12_zeroes(dt) ) // 12 hour format with leading zeroes
        }
        if(p.includes('h')==true){
            p = p.replace( 'h', this.get_hour12(dt) ) // 12 hour format
        }

        //
        //
        // minutes
        if(p.includes('mm')==true){
            p = p.replace( 'mm', this.get_minute_zeroes(dt) ) // minutes with leading zeroes
        }
        if(p.includes('m')==true){
            p = p.replace( 'm', this.get_minute(dt) ) // minutes
        }

        //
        //
        // seconds
        if(p.includes('ss')==true){
            p = p.replace( 'ss', this.get_seconds_zeroes(dt) ) // seconds with leading zeroes
        }
        if(p.includes('s')==true){
            p = p.replace( 's', this.get_seconds(dt) ) // seconds
        }

        //
        //
        // timestamp
        if(p.includes('X')==true){
            p = p.replace( 'X', this.get_seconds(dt) ) // unix timestamp
        }
        if(p.includes('x')==true){
            p = p.replace( 'x', this.get_seconds(dt) ) // unix milliseconds timestamp
        }

        //
        //
        // year
        if(p.includes('YYYY')==true){
            p = p.replace('YYYY',dt.getFullYear()) // 2024
        }
        if(p.includes('YY')==true){
            p = p.replace( 'YY', String(dt.getFullYear()).substring(2) ) // 24
        }
        
        //
        //
        // quarter
        if(p.includes('Qo')==true){
            p = p.replace( 'Qo', this.get_quarter_ordinal(dt) ) // quarter ordinal
        }
        if(p.includes('Q')==true){
            p = p.replace( 'Q', this.get_quarter(dt) ) // quarter
        }
        
        //
        //
        // month
        if(p.includes('MMMM')==true){
            p = p.replace( 'MMMM', this.get_month_name(this.get_month(dt)) ) // month full name
        }
        if(p.includes('MMM')==true){
            p = p.replace( 'MMM', this.get_month_shortname(this.get_month(dt)) ) // month abbreviation
        }
        if(p.includes('MM')==true){
            p = p.replace( 'MM', this.add_zero_months(this.get_month(dt)) ) // month number with leading zeroes
        }
        if(p.includes('Mo')==true){
            p = p.replace( 'Mo', this.get_month_ordinal(dt) ) // month ordinal
        }
        if(p.includes('M')==true){
            p = p.replace( 'M', this.get_month(dt) ) // month number
        }
        
        //
        //
        // week
        if(p.includes('WWo')==true){
            p = p.replace( 'WWo', this.get_week_year_ordinal(dt) ) // week year ordinal
        }
        if(p.includes('WW')==true){
            p = p.replace( 'WW', this.get_week_year(dt) ) // week in a year
        }
        if(p.includes('Wo')==true){
            p = p.replace( 'Wo', this.get_week_month_ordinal(dt) ) // week month ordinal
        }
        if(p.includes('W')==true){
            p = p.replace( 'W', this.get_week_month(dt) ) // week in a month
        }

        //
        //
        // day
        if(p.includes('DDDD')==true){
            p = p.replace( 'DDDD', this.add_zero_days(this.get_day(dt)) ) // day of year with leading zeros
        }
        if(p.includes('DDDo')==true){
            p = p.replace( 'DDDo', this.get_day_year_ordinal(dt) ) // day of year ordinal
        }
        if(p.includes('DDD')==true){
            p = p.replace( 'DDD', this.get_day_year(dt) ) // day of year
        }
        if(p.includes('DD')==true){
            p = p.replace( 'DD', this.add_zero_days(this.get_day(dt)) ) // day of month with leading zero
        }
        if(p.includes('Do')==true){
            p = p.replace( 'Do', this.get_day_month_ordinal(this.get_day(dt)) ) // day of month ordinal
        }
        if(p.includes('D')==true){
            p = p.replace( 'D', this.get_day(dt) ) // day of month
        }
        if(p.includes('do')==true){
            p = p.replace( 'do', this.get_day_week_ordinal(this.get_day(dt)) ) // day of week ordinal
        }
        if(p.includes('d')==true){
            p = p.replace( 'd', this.get_day_of_week(dt) ) // day of week, 1... 7 starts Sunday
        }

        //
        //
        return p

    }

}

//
//
export const init_pendulum_class = function(){

    //
    //
    let x = new class_pendulum()

    //
    //
    return x

}
