
/*
*
*
*
*
*
*/
import { createStore } from 'vuex'
import * as redeemer from '../../public/libraries/redeemer'
import * as forge from '../../public/libraries/forge'
import * as keymaster from '../../public/libraries/keymaster'
import * as crucible from '../../public/libraries/crucible'
import * as bellows from '../../public/libraries/bellows'
import * as pendulum from '../../public/libraries/pendulum'
import * as resources from '../../public/libraries/resources'

/*
*
*
*
*
*
*/
export default createStore({
    state: {

        //
        //
        //
        //
        'CONSTANTS': forge.CONSTANTS,
        'COUNTRY_SELECTION': resources.COUNTRY_SELECTION,
        'CURRENCY_SELECTION': resources.CURRENCY_SELECTION,
        'INDUSTRY_SELECTION': resources.INDUSTRY_SELECTION,
        'COUNTRY_CODES_SELECTION': resources.COUNTRY_CODES_SELECTION,

        // 'USER_LOGGEDIN': false,     // not in used
        // 'USER_COMPANY_VIEW': '',    // current company slug - not in used
        // 'USER_STATE': '',           // 1-owner,2-client,3-personnel - not in use
        // 'USER_PRIVILEGE': {},       // role privileges - not in use

        //
        //
        //
        //
        'COMPLETE_NAME': forge.COMPLETE_NAME,

        'show_debug': forge.show_debug,
        'get_get': forge.get_key(),
        'REDIRECT_TO': forge.REDIRECT_TO,
        'GET_C_URL': forge.GET_C_URL,
        'change_title_tag':forge.change_title_tag,
        'get_randomstring': forge.GET_RANDOMSTRING,
        'show_datetime': forge.show_datetime,
        'show_datetime_ISO': forge.show_datetime_ISO,
        'show_date': forge.show_date,
        'show_date_ISO': forge.show_date_ISO,
        'show_time': forge.show_time,
        'translate_month': forge.translate_month,
        'is_empty': forge.IS_EMPTY,
        'RESIZE_PHOTO': forge.RESIZE_PHOTO,
        'str_json': forge.str_json,
        'is_mobile_browser': forge.is_mobile_browser,
        'show_sidebar': forge.show_sidebar,
        'hide_sidebar': forge.hide_sidebar,
        'get_geopattern': forge.get_geopattern,
        'get_pattern_url': forge.get_pattern_url,
        'CHANGE_BACKGROUND_IMAGE': forge.CHANGE_BACKGROUND_IMAGE,
        'stripHtml': forge.stripHtml,

        'retrieve_access': forge.retrieve_access,
        'get_tinymce_api': forge.get_tinymce_api,
        'page_visibility': forge.page_visibility,
        'feature_visibility': forge.feature_visibility,
        'integrations_available': forge.integrations_available,
        'format_cloudflare': forge.format_cloudflare,
        //'get_api_from_db': forge.get_api_from_db,
        //'if_login_matched': forge.if_login_matched,
        //'if_has_profile': forge.if_has_profile,
        

        //
        // used for session and localstorage management
        //
        //
        'get_key': keymaster.get_key,
        'get_view': keymaster.get_view,
        'set_view': keymaster.set_view,
        'validate_views': keymaster.validate_views,
        'login_session': keymaster.login_session,
        'logout_session': keymaster.logout_session,
        'loggedin_area': keymaster.loggedin_area,
        'get_darkmode': keymaster.get_darkmode,
        'set_darkmode': keymaster.set_darkmode,
        'set_lightmode': keymaster.set_lightmode,
        'manage_state': keymaster.manage_state,
        'view_state': keymaster.view_state,
        //'get_cookie': forge.get_cookie,
        //'encoded_manage_state': forge.encoded_manage_state,
        //'verify_state': forge.verify_state,
        //'setup_state': forge.setup_state,
        //'save_key': forge.save_key,
        //'if_key_exists': forge.if_key_exists,
        //'decoded_view_state': forge.decoded_view_state,
        //'destroy_auth': forge.destroy_auth,
        //'set_cookie': forge.set_cookie,
        //'if_cookie_exists': forge.if_cookie_exists,

        //
        // used for drag n drops
        //
        //
        'kanban_organizer': crucible.kanban_organizer,
        'kanban_drag': crucible.kanban_drag,
        'kanban_drop': crucible.kanban_drop,
        'formb_drag': crucible.formb_drag,
        'formb_dragover': crucible.formb_dragover,
        'formb_dragexit': crucible.formb_dragexit,
        'formb_drop': crucible.formb_drop,
        'formb_sort': crucible.formb_sort,

        //
        // used for workflow builder
        //
        //
        'init_workflow_helper': bellows.init_workflow_helper,
        'init_workflow_filter_operators': bellows.init_workflow_filter_operators,
        'workflow_data_processor': bellows.workflow_data_processor,

        //
        //
        //
        //
        'pendulum': pendulum.init_pendulum_class,

        //
        //
        //
        //
        'redeem_direct': redeemer.redeem_direct,
        'redeem_direct_v2': redeemer.redeem_direct_v2,
        'redeem_api': redeemer.redeem_api,
        'redeem_upload_img': redeemer.redeem_upload_img,
        'redeem_ipaddress': redeemer.redeem_ipaddress,
        'redeem_keyviacode': redeemer.redeem_keyviacode,
        'redeem_keyvialogin': redeemer.redeem_keyvialogin,
        'redeem_keyviaregistration': redeemer.redeem_keyviaregistration,
        'redeem_resend_otp': redeemer.redeem_resend_otp,
        'redeem_verify_otp': redeemer.redeem_verify_otp,

        //
        //
        //
        //
        

    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
